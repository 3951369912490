import { useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ResponsiveTypography } from 'assets/data-display';
import { InvoiceTableRow, ResponsiveTableCell, ResponsiveTableRow } from 'assets/table';
import { useParams } from 'react-router-dom';
import Ingredient from 'services/ingradient';
import Warehouse from 'services/warehouse';
import Response from 'services/response';
import Http from 'services/http';

export default function detail() {
  const { id } = useParams();

  const IService = new Ingredient(useContext(Http.Context)!);
  const IFetch = new Response();

  const WService = new Warehouse(useContext(Http.Context)!);
  const WFetch = new Response();

  useEffect(() => (id && IFetch.handle(IService.getById(id!)), undefined), [id]);
  useEffect(() => (id && WFetch.handle(WService.get()), undefined), [id]);
  const stock_types: any = {
    1: 'Ticari Mal',
    2: 'Yarı Mamül',
    3: 'Hizmet',
    4: 'Demirbaş',
  };

  const findWarehouse = (warehouse: string) => {
    return WFetch.data?.find((w: any) => w.id == warehouse)?.title || 'Depo bulunamadı';
  };

  const shelLifeFormat = (type: string) => {
    switch (type) {
      case 'DAY':
        return 'gün';
      case 'WEEK':
        return 'hafta';
      case 'MONTH':
        return 'ay';
      case 'YEAR':
        return 'yıl';
      default:
        return ' ';
    }
  };

  const getColor = (action: number) => {
    switch (action) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 10:
      case 12:
      case 14:
      case 15:
      case 16:
        return false;
      default:
        return true;
    }
  };

  const getData = () => {
    let data = IFetch.data?.purchases.concat(IFetch.data?.sales || []);
    data.sort((a: any, b: any) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    return data || [];
  };

  enum EActionTypes {
    'Stok Satış' = 1,
    'Yarı Mamül Satış' = 2,
    'Reçete Satış' = 3,
    'Pos Sipariş' = 4,
    'Stok Satış İade' = 5,
    'Yarı Mamül İade' = 6,
    'Reçete İade' = 7,
    'Pos Sipariş İptal' = 8,
    'Satış Faturası Silme' = 9,
    'Satış İade Faturası Silme' = 10,
    'Satış Faturası Yarı Mamül Silme' = 11,
    'Satış İade Faturası Yarı Mamül Silme' = 12,
    'Satış Faturası Reçete Silme' = 13,
    'Satış İade Faturası Reçete Silme' = 14,
    'Stok Alış İade' = 15,
    'Fatura Güncelleme Azaltma' = 16,
    'Fatura Güncelleme Ekleme' = 17,
  }

  return (
    <Box sx={{ overflowY: 'scroll', padding: '1rem' }}>
      <Typography
        variant='h4'
        fontWeight='bold'
        sx={{
          marginBottom: '1rem',
          fontSize: '2rem',
          color: 'black',
          textTransform: 'uppercase',
        }}
      >
        Stok Detayları
      </Typography>
      <Divider sx={{ marginY: '1rem' }} />

      <Box sx={{ marginTop: '1rem' }}>
        {IFetch.data ? (
          <Paper elevation={3} sx={{ padding: '1rem' }}>
            <Stack direction='row' justifyContent='space-around'>
              <Box>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Başlık:</strong> {IFetch.data.title}
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Stok Kodu:</strong> {IFetch.data.stock_code}
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Satış Fiyatı:</strong> {IFetch.data.product_amount}₺
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>KDV Oranı:</strong> {IFetch.data.vat_rate}%
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Depo:</strong> {findWarehouse(IFetch.data?.warehouse)}
                </Typography>
              </Box>

              <Box>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Stok:</strong> {IFetch.data.stock}
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Optimum Stok Miktarı:</strong> {IFetch.data.optimal_stock_quantity}
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Kritik Stok Miktarı:</strong> {IFetch.data.critical_stock_quantity}₺
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Stok Grubu:</strong>{' '}
                  {
                    (Object.values(stock_types) as (keyof typeof stock_types)[])[
                      IFetch.data?.stock_group - 1
                    ]
                  }
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  <strong>Raf Ömrü:</strong> {IFetch.data?.shelf_life}{' '}
                  {shelLifeFormat(IFetch.data?.shelf_life_type)}
                </Typography>
              </Box>
            </Stack>

            <Divider sx={{ marginY: '1rem' }} />

            {IFetch.data.purchases && IFetch.data.purchases.length > 0 ? (
              <TableContainer component={Paper} elevation={3} sx={{ marginBottom: '1rem' }}>
                <Table sx={{ margin: 'auto', width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>İşlem</TableCell>
                      <TableCell>Alış / Satış Fiyatı</TableCell>
                      <TableCell>Miktar</TableCell>
                      <TableCell>Kalan</TableCell>
                      <TableCell>Alımdan Kalan</TableCell>
                      <TableCell>Tarih</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getData().map((purchase: any) => (
                      <InvoiceTableRow isPaid={getColor(purchase?.actionType)} key={purchase?.id}>
                        <TableCell>
                          {purchase.actionType
                            ? EActionTypes[purchase.actionType]
                            : 'Alış Faturası'}
                        </TableCell>
                        <TableCell>{purchase?.purchase_price || purchase?.amount || '-'}</TableCell>
                        <TableCell>{purchase?.stock || purchase?.quantity}</TableCell>
                        <TableCell>{purchase?.total_remaining}</TableCell>
                        <TableCell>{purchase?.remaining}</TableCell>
                        <TableCell>{new Date(purchase?.createdAt)?.toLocaleString()}</TableCell>
                      </InvoiceTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                fontWeight='bold'
                variant='body1'
                sx={{ fontSize: '1.6rem', textAlign: 'center', color: '#777' }}
              >
                Bu stok için hiç satın alım yapılmamış.
              </Typography>
            )}
          </Paper>
        ) : (
          <Typography
            variant='body1'
            sx={{ fontSize: '1.6rem', textAlign: 'center', color: '#777' }}
          >
            Veriler yükleniyor...
          </Typography>
        )}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ overflowY: 'scroll' }}>
      <Box sx={{ padding: '1vw' }}>
        <ResponsiveTypography sx={{ fontSize: '2vw' }} variant='h4' fontWeight='bold'>
          Stok Detayları
        </ResponsiveTypography>
      </Box>
      <hr />
      <Box sx={{ marginTop: '1vw' }}>
        <Stack direction='column' spacing={8} alignItems='center' justifyContent='space-around'>
          <Stack direction='row' justifyContent='space-around' spacing={5}>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Başlık: </strong>
              {IFetch.data?.title}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Stok Kodu: </strong>
              {IFetch.data?.stock_code}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Satış Fiyatı: </strong>
              {IFetch.data?.product_amount}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>KDV Oranı: </strong>
              {IFetch.data?.vat_rate}%
            </ResponsiveTypography>
          </Stack>
          <Stack direction='row' justifyContent='space-around' spacing={5}>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Stok: </strong>
              {IFetch.data?.stock}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Optimum Stok Miktarı: </strong>
              {IFetch.data?.optimal_stock_quantity}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Kritik Stok Miktarı: </strong>
              {IFetch.data?.critical_stock_quantity}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Stok Grubu: </strong>
              {
                (Object.values(stock_types) as (keyof typeof stock_types)[])[
                  IFetch.data?.stock_group - 1
                ]
              }
            </ResponsiveTypography>
          </Stack>
          <Stack direction='row' justifyContent='space-around' spacing={5}>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Raf Ömrü: </strong>
              {IFetch.data?.shelf_life} {shelLifeFormat(IFetch.data?.shelf_life_type)}
            </ResponsiveTypography>
            <ResponsiveTypography sx={{ fontSize: '1.3vw' }}>
              <strong>Depo: </strong>
              {findWarehouse(IFetch.data?.warehouse)}
            </ResponsiveTypography>
          </Stack>
        </Stack>
      </Box>
      <hr style={{ marginTop: '2vw', marginBottom: '2vw' }} />
      <Box>
        {IFetch.data?.purchases?.length > 0 ? (
          <TableContainer>
            <Table sx={{ margin: 'auto', width: '70%' }}>
              <TableHead>
                <ResponsiveTableRow>
                  <ResponsiveTableCell>Alış Fiyatı</ResponsiveTableCell>
                  <ResponsiveTableCell>Miktar</ResponsiveTableCell>
                  <ResponsiveTableCell>Kalan</ResponsiveTableCell>
                  <ResponsiveTableCell>Tarih</ResponsiveTableCell>
                </ResponsiveTableRow>
              </TableHead>
              <TableBody>
                {IFetch.data?.purchases?.map((purchase: any) => (
                  <ResponsiveTableRow>
                    <ResponsiveTableCell>{purchase.purchase_price}₺</ResponsiveTableCell>
                    <ResponsiveTableCell>{purchase.stock}</ResponsiveTableCell>
                    <ResponsiveTableCell>{purchase.remaining}</ResponsiveTableCell>
                    <ResponsiveTableCell>
                      {new Date(purchase.createdAt)?.toLocaleString()}
                    </ResponsiveTableCell>
                  </ResponsiveTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <ResponsiveTypography fontWeight='bold' sx={{ fontSize: '1.6vw' }} textAlign='center'>
            Bu stok için hiç satın alım yapılmamış.
          </ResponsiveTypography>
        )}
      </Box>
    </Box>
  );
}
